.search {
  width: 100%;
}
.search .dropdown-menu {
  display: block;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}

.customModal {
  left: 0;
  top: 0;
  background: rgba(75, 73, 73, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .customModal .contain {
  
  height: 100%;
  width: 100%;
} */

.popin {
  background-color: white;
  max-width: 80%;
  max-height: 50%;
  overflow-y: scroll;
  position: relative;
  padding: 60px;
}

.popin .close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.upload-area {
  width: 100%
}

.upload-area input {
  display: none;
}

.upload-area label {
  width: 100%;
  border: 3px #efa00b dashed;
  padding: 3em 1.78em;
  display: inline-block;
  cursor: pointer;
  font-family: "Noto Sans JP", sans-serif;
}

.upload-area label h1 {
  font-size: 130%;
  text-align: center;
  color: #efa00b;
  display: block;
}

.upload-area .file-drop-error {
  width: 100%;
  text-align: center;
  color: red;
  display: inline-block;
  margin-bottom: 1em;
}